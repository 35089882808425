<template>
    <div>
        <portal to="layout-default-header-container">
            <div
                class="bg-overlay bg-cover"
                :style="headerStyle"
            >
                <whitelabel-leagues-nav
                    v-if="whitelabel.id"
                />

                <b-container
                    v-else
                    class="px-0"
                >
                    <navbar type="dark" />
                </b-container>

                <b-container class="text-white">
                    <b-row>
                        <b-col class="py-5">
                            <div class="pt-5">
                                <h1 class="mb-0">
                                    <!-- Use `get` to maintain reactivity. -->
                                    {{ league.get('name') }}
                                </h1>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </div>

            <b-container
                v-if="hasBottomHeader"
                class="mt-5"
            >
                <portal-target
                    name="layout-default-header"
                    slim
                />
            </b-container>
        </portal>

        <template v-if="showHeader && !league.loading">
            <portal to="layout-default-secondary-navbar">
                <b-nav
                    class="secondary-navbar font-weight-bold"
                    fill
                >
                    <b-nav-item
                        v-for="(route, i) in navRoutes"
                        :key="`ldr-${i}`"
                        :to="route"
                    >
                        {{ $t(`${route.name}.title`) }}
                    </b-nav-item>
                </b-nav>
            </portal>
        </template>

        <wait-for-resource :resource="league">
            <router-view :league="league" />
        </wait-for-resource>
    </div>
</template>

<script>
import {Wormhole} from 'portal-vue';
import {League} from '@/models/League';
import Me from '@/library/Me';
import Navbar from '@/components/common/Navbar';
import Whitelabel from '@/library/Whitelabel';
import WhitelabelLeaguesNav from '@/components/whitelabels/LeaguesNav';
import {leagueDetailsRoutes} from '@/router/routes/leagues';
import {redirectToSchedule} from '@/router/routes/leagues';

export default {
    name: 'LeaguesRouterView',
    components: {Navbar, WhitelabelLeaguesNav},
    props: {
        slug: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            league: new League({slug: this.slug}),
            me: new Me,
            whitelabel: new Whitelabel,
            headerStyle: {
                backgroundColor: '#000',
            },
        };
    },
    computed: {
        /**
         * Filters routes according to the currently authenticated user.
         * Certain routes should not be shown when the user is not an admin
         * within a team of the league.
         *
         * @returns {array}
         */
        navRoutes() {
            const isTeamAdmin = !!this.me.id
                && !!this.league.current_user_team_user
                && this.league.current_user_team_user.roles.includes('admin');

            return leagueDetailsRoutes.filter((route) => {
                const middleware = _.get(route, 'meta.middleware', []);

                const noNav = _.get(route, 'meta.noNav', false);

                const configuration = this.league.get('data.ui.menu_items');

                let configured = true;

                if (configuration && _.get(route, 'meta.configurable', false)) {
                    configured = !!configuration[route.name];
                }

                return noNav === false
                    && (isTeamAdmin || !middleware.includes('Auth'))
                    && configured;
            });
        },
        /**
         * Shows header if the current route is part of `leagueDetailsRoutes`.
         *
         * @returns {boolean}
         */
        showHeader() {
            return _.get(this, '$route.meta.showLeagueHeader', false)
                || leagueDetailsRoutes.findIndex(r => r.name === this.$route.name) != -1;
        },
        hasBottomHeader() {
            return Wormhole.hasContentFor('layout-default-header');
        },
    },
    watch: {
        slug(value) {
            this.league.slug = value;

            this.fetch();
        },
    },
    created() {
        this.fetch();
    },
    beforeRouteUpdate: redirectToSchedule,
    methods: {
        async fetch() {
            try {
                await this.league.fetch();
            } catch (error) {}

            this.headerStyle = {
                backgroundColor: this.league.card_background_color,
                backgroundImage: `url('${this.league.header_background_image}')`,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.secondary-navbar {
    @include media-breakpoint-down(sm) {
        flex-wrap: nowrap;
        overflow-x: scroll;

        .nav-item {
            white-space: nowrap;

            .nav-link {
                padding: .5rem;
            }
        }
    }
}
</style>
